import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as DeleteOutlineIcon } from "../../../assets/images/Delete Icon (2).svg";
import { ReactComponent as ModeEditIcon } from "../../../assets/images/Edit Icon (1).svg";
import { ReactComponent as AddIcon } from "../../../assets/images/Plus icon (1).svg";
import { ReactComponent as Filter } from "../../../assets/images/filter_Icon.svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import DataTable from "../../../components/DataTable";
import IconHandler from "../../../components/IconHandler";
import EmployeeService from "../../../services/EmployeeService";
import EmployeeFilter from "./EmployeeFilter";

import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import RightViewLayout from "../../../components/RighViewLayout";
import EmployeeAdd from "./EmployeeAdd";
import { useNavigate } from "react-router";
import { REACT_ADD_EMPLOYEE, REACT_EMPLOYEE_LIST } from "../../../constants/ReactEndPoints";
import { t } from "i18next";

const options = [
  { value: 'Today', label: 'Today' },
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'Week', label: 'Week' },
];

export default function Employee() {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showButtonEmployee, setShowButtonEmployyee] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [deptFilterFlag, setDeptFilterFlag] = useState(false);
  const [userFiletrFlag, setUserFilterFlag] = useState(false)
  const [batchMove, setBatchMove] = useState(false);
  const [batchMoveLocation, setBatchMoveLocation] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");

  const [pageOperation, setPageOperation] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [rows, setRows] = useState([]);
  const [flag, setFlag] = useState(false);
  const [filters, setFilters] = useState({});
  const [callApiFilter, setcallApiFilter] = useState(true);

  const defaultFilter = {
    firstName: "",
    lastName: "",
    email: "",
  };

  useEffect(() => {
    if (callApiFilter) {
      userFilter = selectedValue;
      fetchEmployees();
      setcallApiFilter(false);
    }
  }, [callApiFilter]);

  const handleAddUser = () => {
    setFlag(true);
    setTitle(t("USER010"))
  }

  const handleFilter = () => {
    setDeptFilterFlag(true);
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      width: 200,
      renderCell: (e) => <input type="hidden" value={e.row.id} />,
      editable: false,
      hide: true,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "firstName",
      headerName:t("USER5"),
      flex: 1.2,
      width: 200,
      renderCell: (e) => e.row.firstName + " " + e.row.lastName,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "roleName",
      headerName: t("USER6"),
      flex: 1,
      width: 200,
      renderCell: (e) => e.row.roleName,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "email",
      headerName: t("USER7"),
      flex: 1.5,
      width: 200,
      renderCell: (e) => e.row.email,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "phone",
      headerName: t("USER8"),
      flex: 1.2,
      width: 200,
      renderCell: (e) => e.row.phone,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "country",
      headerName: t("USER9"),
      flex: 1,
      width: 200,
      renderCell: (e) => e.row.country,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      headerName: t("USER10"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandler>
          <Tooltip title={t("USER11")} arrow>
            <ModeEditIcon id="editUser"
              onClick={() => handleActions("Edit", e)}
              style={{ margin: "7px", width: '1em' }}
            />
          </Tooltip>
          <Tooltip title={t("USER12")} arrow>
            <DeleteOutlineIcon id="deleteUser"
              onClick={() => handleActions("Delete", e)}
              style={{ margin: "7px", width: '0.8em' }}
            />
          </Tooltip>
        </IconHandler>
      ),
    },
  ];

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  var userFilter = "";

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedValue(value)
    userFilter = value;
    setFilters({
      ...defaultFilter
    });
    fetchEmployees();
    setPageOperation({ ...pageOperation, page: 0 });
  };

  const handleActions = (action, data) => {
    if (action === "View") {
    } else if (action === "Edit") {
      setTitle("Edit User")
      setId(data["id"]);
      setFlag(true)
    } else if (action === "Delete") {
      EmployeeService.deleteUserById(data["id"]).then((res) => {
        if (res.data.code === 'ZKCSAE0000' || res.data.code === 'ZKCSAE0002') {
          toast.error("Something went Wrong.", {
            position: "top-right"
          });
        }
        else {
          fetchEmployees();
          toast.success('User Deleted Successfully', {
            position: "top-right"
          });
        }
      });
    }
  };

  const handleSelectedRows = (data) => {
    setSelectedEmployee(data);
  };

  // --------------------refresh ----------------------------//

  const handleRefresh = () => {
    setSelectedValue("");
    setFilters({
      ...defaultFilter
    });
    setPageOperation({ page: 0, rowsPerPage: 10 });
    setcallApiFilter(false);
    // fetchEmployees(true);
    EmployeeService.getAllEmployees( 1, 10, "").then((res) => {
      if (res !== undefined) {
        if (res.data.code === "ZKCSAE0001") {
          setRows(res.data.data.userList);
          setTotalRecords(res?.data?.data?.totalCount);
        } else {
          setRows([]);
          setTotalRecords(0);
        }

      }
    });
  }

  // --------------fetchEmployees---------------

  const fetchEmployees = (refresh) => {
    let query = "";
    if (!refresh) {
      
      if (userFiletrFlag && (filters?.firstName !== "" || filters?.lastName !== "" || filters?.email !== "")) {
        if (filters?.firstName) {
          query += "&firstName=" + filters.firstName;
          setSelectedValue("");
          userFilter = ""

        }

        if (filters?.lastName) {
          query += "&lastName=" + filters.lastName;
          setSelectedValue("");
          userFilter = ""
        }

        if (filters?.email) {
          query += "&email=" + filters.email;
          userFilter = ""
          setSelectedValue("");
        }
      } else if (!userFiletrFlag && (filters?.firstName !== "" || filters?.lastName !== "" || filters?.email !== "") && userFilter ==="") {
        if (filters?.firstName) {
          query += "&firstName=" + filters.firstName;
          setSelectedValue("");
          userFilter = ""

        }

        if (filters?.lastName) {
          query += "&lastName=" + filters.lastName;
          setSelectedValue("");
          userFilter = ""
        }

        if (filters?.email) {
          query += "&email=" + filters.email;
          userFilter = ""
          setSelectedValue("");
        }
      } else {
        if (userFilter) {
          query += "&filter=" + userFilter;
        }
      }
    }

    if (query === "") {
      EmployeeService.getAllEmployees(pageOperation.page + 1, pageOperation.rowsPerPage, "").then((res) => {
        if (res !== undefined) {
          if (res.data.code === "ZKCSAE0001") {
            setRows(res.data.data.userList);
            setTotalRecords(res?.data?.data?.totalCount);
          } else {
            setRows([]);
            setTotalRecords(0);
          }

        }
      });
    } else if (query !== "") {
      EmployeeService.getAllEmployees(pageOperation.page + 1, pageOperation.rowsPerPage, query).then((res) => {
        if (res !== undefined) {
          if (res.data.code === "ZKCSAE0001") {
            setRows(res.data.data.userList);
            setTotalRecords(res?.data?.data?.totalCount);
            setUserFilterFlag(false);
          } else {
            setRows([]);
            setTotalRecords(0);
          }

        }
      }
      );
    }
  };

  const handleAddEmployee = () => {
    setFlag(true);
  };

  const handleChangePage = (newPage) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: newPage });
    setcallApiFilter(true);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
    setcallApiFilter(true);
  };



  const dataTableStyle = {
    "& .MuiDataGrid-colCell": {
      backgroundColor: "red",
      color: "white",
    },
  };


  const actions = () => (
    <Box item style={{ display: "inherit" }}>
      <CustomDropDownDatePicker value={selectedValue} onChange={handleChange} options={options} />
      <Tooltip title={t("USER2")} arrow>
        <Refresh id="refresh-userList"
          width={"40px"}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => {
            handleRefresh();
          }}
        />
      </Tooltip>
      <Tooltip arrow title={t("USER3")}>
        <Filter id="filter-userList"
          width={"40px"}
          onClick={() => handleFilter()}
          style={{ marginRight: "10px", cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip arrow title={t("USER4")}>
        <AddIcon id="addUser-userList"
          width={"40px"}
          onClick={() => handleAddUser()}
          style={{ marginRight: "10px", cursor: "pointer" }}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Grid >
      <RightViewLayout
        title={t("USER1")}
        actions={actions}
        type="table"    >
        <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
          <DataTable
            columns={columns}
            rows={rows}
            headerClassName="custom-header-color"
            style={{ dataTableStyle }}
            onSelection={(data) => handleSelectedRows(data)}
            page={pageOperation.page}
            totalRecords={totalRecords}
            rowsPerPage={pageOperation.rowsPerPage}
            onPageChange={(pn) => handleChangePage(pn)}
            onPageSizeChange={(ps) => handleChangeRowsPerPage(ps)}
            checkboxSelection
            disableRowSelectionOnClick

          />

          {flag && (
            <EmployeeAdd
              title={title}
              flag={flag}
              id={id}
              setId={setId}
              setFlag={setFlag}
              loadData={fetchEmployees}
            />
          )}

          {deptFilterFlag && (
            <EmployeeFilter
              flag={deptFilterFlag}
              setFlag={setDeptFilterFlag}
              filterFlag={setUserFilterFlag}
              filters={filters}
              setFilters={setFilters}
              loadData={fetchEmployees}
              setcallApiFilter={setcallApiFilter}
            />
          )}
        </Grid>
      </RightViewLayout>
    </Grid>
  );
}
