import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import RightViewLayout from "../../../components/RighViewLayout";
import DropzoneComponent from "../../../components/layout-components/DropzoneComponent";
import { REACT_HOME_SCREEN } from "../../../constants/ReactEndPoints";
import AdvertService from '../../../services/AdvertService';
import useStyles from '../../onboard/Styles';
import { getAllTags } from '../../../services/TagService';
import Select from '@mui/material/Select';
import CustomInput from '../../../components/CustomInput';
import { ReactComponent as Plus } from "../../../assets/images/Plus_icon.svg";
import { useSnackbar } from 'notistack';
import { ReactComponent as AddIcon } from "../../../assets/images/Plus icon (1).svg";



export default function UploadTemplate(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [chooseFile, setChooseFile] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [previewkey, setPreviewkey] = useState(null);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    const [message7, setMessage7] = useState("");
    const [file, setFile] = useState("");
    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const [file3, setFile3] = useState("");

    const [fields, setFields] = useState([]);
    const [newFields, setNewFields] = useState([]);
    const [check, setCheck] = useState(false);
    const [newCardIndex, setNewCardIndex] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [placeHolderImageUrl, setPlaceHolderImageUrl] = useState(null);
    const [placeHolderkey, setPlaceHolderkey] = useState(null);

    const [loading, setLoading] = useState(false);
    const types = ["String", "Number"];

    useEffect(() => {
        loadAllTag();
    }, [])


    const templateNameHandler = (event) => {
        const values = event.target.value;
        setTemplateName(values);
        if (values.trim() === '') {
            setMessage6(t('ADDTEMP17'));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,20}$/.test(values)) {
            setMessage6(t('ADDTEMP18'));
        } else {
            setMessage6('');
        }
    };


    const tagsHandler = (event) => {
        // debugger
        const {
            target: { value }, } = event;
        setTags(typeof value === 'string' ? value.split(',') : value);

        if (value.length === 0) {
            setMessage1(t('ADDTEMP19'))
        } else {
            setMessage1('');
        }
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const handleClose = () => {
        setMenuOpen(false);
    };

    const descriptionHandler = (event) => {
        const values = event.target.value;

        setDescription(values);
        if (values.trim() === '') {
            setMessage2(t('ADDTEMP21'));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,500}$/g.test(values)) {
            setMessage2(t('ADDTEMP22'));
        } else {
            setMessage2('');
        }
    };

    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

    // const handleImageUpload = (event) => {
    //     const file = event.target.files[0];
    //     if (!file) {
    //         setMessage3('Please upload a valid image (JPEG, PNG or SVG)');
    //         return;
    //     } else {
    //         setFile(file);
    //         setMessage3('');
    //     }
    //     if (!allowedImageTypes.includes(file.type)) {
    //         setMessage3('Please upload a valid image (JPEG, PNG or SVG)');
    //         return;
    //     }
    // };

    // addAttribte
    const handleAddField = () => {
        // if (newFields.length === 0) {
        const newField = { label: "", type: "", name: "" };
        setNewFields([...newFields, newField]);
        setCheck(true);
        // }

        if (newFields.length > 0) {
            const newCardIndex = newFields.length - 1;
            console.log(newCardIndex);
            const newCardElement = document.getElementById(`card-${newCardIndex}`);

            if (newCardElement) {
                newCardElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            } else {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            }
        }
    };

    const [name, setName] = useState('');
    const handleFieldChange = (index, field, value) => {
        // debugger
        setName(value);
        const { name, value: fieldValue } = value.target || value;
        const updatedFields = [...newFields];

        if (name === "options") {
            const optionsArray = fieldValue.split(",");
            field[name] = optionsArray;
        } else {
            field[name] = fieldValue;
            console.log("field[name] :", field[name]);
            console.log("FieldValue :", fieldValue);

        }

        updatedFields[index] = { ...field };
        setNewFields(updatedFields);
    };

    const removeField = (indexToRemove) => {
        const updated = newFields.filter((_, index) => index !== indexToRemove);
        setNewFields(updated);
    };

    const handleActions = (action, data) => {
        if (action === "Delete") {
            //   setId(data)
            //   setConfirm(true);
            localStorage.removeItem('newFields');
        }
    };

    const handleImageUpload = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage3(t('ADDTEMP23'));
        } else {
            setFile(excelData[0]);
            setChooseFile(excelData[0].name);
            setMessage3('');
            props.getExcelFile(excelData[0]);
        }
        if (!allowedImageTypes.includes(excelData.type)) {
            setMessage3(t('ADDTEMP23'));
            return;
        }
    };

    const handleImageUploadThumbnail = (excelData) => {

        const file = excelData[0];
        if (!file) {
            setMessage4(t('ADDTEMP23'));
        } else {
            setMessage4('');
            setFile1(excelData[0]);
            setThumbnailImage(excelData[0].name);
            setThumbnailImageUrl(URL.createObjectURL(excelData[0]));
            props.getExcelFile(excelData[0]);

        }

    };

    const handleImageUploadPreview = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage5(t('ADDTEMP23'));
        } else {
            setMessage5('');
            setFile2(excelData[0]);
            setPreviewkey(excelData[0].name);
            setPreviewImageUrl(URL.createObjectURL(excelData[0]));
            props.getExcelFile(excelData[0]);
        }
    };

    const handleImageUploadPlaceHolder = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage7(t('ADDTEMP23'));
        } else {
            setMessage7('');
            setFile3(excelData[0]);
            setPlaceHolderkey(excelData[0].name);
            setPlaceHolderImageUrl(URL.createObjectURL(excelData[0]));
            props.getExcelFile(excelData[0]);
        }
    };

    const payload = {
        description: description,
        tags: tags,
        newFields: newFields
    };

    const handleCancel = () => {
        navigate(REACT_HOME_SCREEN);
    };

    const isFieldEmpty = (field) => {
        console.log("Received Field Value : ", field);
        //return !field.label.trim() || !field.type.trim();
        // return field.label == "" || field.type == "";
        let isContain = ["Title", "Body Content", "WebSite", "Other Changes", "Refrence Image", "Logo"];
        if (isContain.includes(field.label)) {
            enqueueSnackbar(t("ADDTEMP33"), { variant: "error" });
            return true;
        }
        if (field.label == "" || field.type == "" || field.type == null) {
            enqueueSnackbar(t("ADDTEMP34"), { variant: "error" });
            return true;
        }
    };

    const capitalizeFirst = (str) => {
        const lowerCaseStr = str.toLowerCase();
        // Capitalize the first letter of each word
        return lowerCaseStr.replace(/(^|\s)\w/g, (match) => match.toUpperCase());
    };

    const toCamelCase = (str) => {
        return str
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    };

    const handleSubmit = async (event) => {
        if (newFields.some((field) => isFieldEmpty(field))) {
            return;
        }

        let isError = false; // Flag to indicate if there's an error

        newFields.forEach((field) => {
            field.label = capitalizeFirst(field.label);
            field.name = toCamelCase(field.label);

            // const alphanumericWithSpaceRegex = /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
            // if (!alphanumericWithSpaceRegex.test(field.label)) {
            //     enqueueSnackbar("Field must be alphanumeric with only one space between words and does not contain special characters.", { variant: "error" });
            //     isError = true; // Set isError flag to true if there's an error
            // }

            if (field.label.length < 5 || field.label.length > 20) {
                enqueueSnackbar(t("ADDTEMP35"), { variant: "error" });
                isError = true; // Set isError flag to true if there's an error
            }

            const specialCharactersRegex = /[^a-zA-Z0-9\s]/;
            if (specialCharactersRegex.test(field.label)) {
                enqueueSnackbar(t("ADDTEMP36"), { variant: "error" });
                isError = true; // Set isError flag to true if there's an error
            }
        });

        if (isError) {
            return; // Return early if there's an error
        }

        if (templateName === '') {
            setMessage6(t('ADDTEMP17'));
            return;
        }

        if (tags.length === 0) {
            setMessage1(t('ADDTEMP19'));
            return;
        }

        if (description === '') {
            setMessage2(t('ADDTEMP21'));
            return;
        }

        if (file === '' || file === 'null') {
            setMessage3(t('ADDTEMP26'));
            return;
        }

        if (file1 === '' || file1 === 'null') {
            setMessage4(t('ADDTEMP27'));
            return;
        }

        if (file2 === '' || file2 === 'null') {
            setMessage5(t('ADDTEMP28'));
            return;
        }

        if (file3 === '' || file3 === 'null') {
            setMessage7(t('ADDTEMP29'));
            return;
        }

        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file1', file1);
        formData.append('file2', file2);
        formData.append('file3', file3);
        formData.append('tags', tags);
        formData.append('description', description);
        formData.append('advertType', isPremiumTemplate ? 'premium' : 'public');
        formData.append('templateName', templateName);
        formData.append("token", localStorage.getItem('USER_TOKEN'));
        formData.append("country", localStorage.getItem('COUNTRY'));
        formData.append('newFields', JSON.stringify(newFields));

        AdvertService.createAdvert(formData, payload).then((res) => {
            if (res.data.code === 'ZKCSAI0001') {
                toast.success(t("ADDTEMP24"), { position: "top-right" });
                setLoading(false);
                navigate(REACT_HOME_SCREEN);
            } else if (res.data.code === 'ZKCSAE0001') {
                toast.error(t("ADDTEMP25"), { position: "top-right" });
                setLoading(false);
            } else {
                toast.error(res.data.message, { position: "top-right" });
                setLoading(false);
            }
        });
    };
    const [isPremiumTemplate, setIsPremiumTemplate] = React.useState(false);

    const handlePremiumTemplate = (event) => {
        setIsPremiumTemplate(!isPremiumTemplate);
    };

    const [allTags, setAllTags] = useState([]);

    const loadAllTag = () => {
        getAllTags(1, 1000).then((res) => {
            console.log("loadAllTag", res?.data?.data.tagsList)
            if (res?.data?.code === "ZKCSAI0001") {
                setAllTags(res?.data?.data.tagsList);
            }
        });
    }


    return (

        <Grid>
            <RightViewLayout
                title={t("ADDTEMP1")}
            >
                {loading ?
                    <Loader />
                    :
                    <Grid container marginLeft={'24px'} overflow={"initial"} display="flex" >
                        <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} className='uploadStyle'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body2" sx={{ textAlign: 'left', opacity: '1' }} >
                                    <span><strong>{t("ADDTEMP2")}</strong></span> {t("ADDTEMP3")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body2">
                                    {t("ADDTEMP4")}<span className={classes.redAsterisk}>*</span>
                                </Typography>
                                <TextField fullWidth variant="outlined" value={templateName} name="description"
                                    placeholder={t("ADDTEMP5")} onChange={templateNameHandler} />
                                {message6 && <div className={classes.redColor}>{message6}</div>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography variant="body2">
                                    {t("ADDTEMP6")}<span className={classes.redAsterisk}> *</span>
                                </Typography>
                                <Select
                                    displayEmpty
                                    multiple
                                    fullWidth
                                    // label={tags.length === 0 ? "Select Tags" : ""}
                                    value={tags}
                                    onChange={tagsHandler}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <Typography sx={{ fontSize: '12px', color: '#ADADAD', fontWeight: '300' }}>
                                                {t("ADDTEMP7")}
                                            </Typography>;
                                        }
                                        return selected.join(', ');
                                    }}
                                    variant="outlined"
                                    onClose={handleClose}
                                    onOpen={() => setMenuOpen(true)}
                                    open={menuOpen}
                                >
                                    {allTags.map(option => (
                                        <MenuItem key={option.id} value={option.tag}>
                                            {option.tag}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {message1 && <div className={classes.redColor}>{message1}</div>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography variant="body2">
                                    {t("ADDTEMP16")}<span className={classes.redAsterisk}>  *</span>
                                </Typography>
                                {/* <TextField type="file" multiple={false} onChange={handleImageUpload}
                                fullWidth variant="outlined" name="file" placeholder='Upload Original Image' /> */}
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUpload(excelData)}>
                                    <Box sx={{ border: '1px solid #ccc', p: '7px', borderRadius: '3px' }} >
                                        <Button id='uploadTem-button' variant='contained' size='small' sx={{ textTransform: 'capitalize' }}>{t("BAN01")}</Button> &nbsp;
                                        <Typography variant="caption" color={'#C8C8C8'} sx={{ fontWeight: '300', pointerEvents: 'none' }}>
                                            {chooseFile !== null ? (chooseFile) : t('ADDTEMP8')}
                                        </Typography>

                                    </Box>
                                    {message3 && <div className={classes.redColor}>{message3}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body2">
                                    {t("ADDTEMP9")}<span className={classes.redAsterisk}>  *</span>
                                </Typography>
                                <TextField fullWidth variant="outlined" value={description} name="description" placeholder={t("ADDTEMP10")} onChange={descriptionHandler} />
                                {message2 && <div className={classes.redColor}>{message2}</div>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox size="small" name="advertType" checked={isPremiumTemplate} onChange={handlePremiumTemplate}></Checkbox>}
                                        label={t("ADDTEMP15")}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                                <Typography variant="body2">
                                    {t("ADDTEMP12")}<span className={classes.redAsterisk}> *</span>
                                </Typography>
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadThumbnail(excelData)}>
                                    {thumbnailImageUrl ? (
                                        <img src={thumbnailImageUrl} alt="Thumbnail Preview" draggable="false" style={{ width: '100%', height: 'auto' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #36C96D', borderRadius: '5px',
                                            backgroundColor: "rgba(54, 201, 109,0.1)", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }} >
                                            <Typography sx={{ fontSize: "14px", textAlign: 'center', opacity: '0.8' }}>
                                                {thumbnailImage !== null ? (
                                                    thumbnailImage
                                                ) : t("ADDTEMP11")}
                                            </Typography>
                                        </Box>
                                    )}
                                    {message4 && <div className={classes.redColor}>{message4}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                                <Typography variant="body2">
                                    {t("ADDTEMP13")}<span className={classes.redAsterisk}> *</span>
                                </Typography>
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadPreview(excelData)}>
                                    {previewImageUrl ? (
                                        <img src={previewImageUrl} draggable="false" alt="Preview" style={{ width: '100%', height: 'auto' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #36C96D', borderRadius: '5px',
                                            backgroundColor: "rgba(54, 201, 109,0.1)", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }}>
                                            <Typography type="file" sx={{ fontSize: "14px", textAlign: 'center', opacity: '0.8' }}>
                                                {previewkey !== null ? previewkey : t("ADDTEMP11")}
                                            </Typography>
                                        </Box>
                                    )}
                                    {message5 && <div className={classes.redColor}>{message5}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item md={4} lg={4} xl={4} sm={12} xs={12}>
                                <Typography variant="body2">
                                    {t("ADDTEMP14")}<span className={classes.redAsterisk}> *</span>
                                </Typography>
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadPlaceHolder(excelData)}>
                                    {placeHolderImageUrl ? (
                                        <img src={placeHolderImageUrl} draggable="false" alt="Preview" style={{ width: '100%', height: 'auto' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #36C96D', borderRadius: '5px',
                                            backgroundColor: "rgba(54, 201, 109,0.1)", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }}>
                                            <Typography type="file" sx={{ fontSize: "14px", textAlign: 'center', opacity: '0.8' }}>
                                                {placeHolderkey !== null ? placeHolderkey : t("ADDTEMP11")}
                                            </Typography>
                                        </Box>
                                    )}
                                    {message7 && <div className={classes.redColor}>{message7}</div>}
                                </DropzoneComponent>
                            </Grid>
                            {fields.map((field, index) => (
                                <Grid key={index} item container spacing={2} >
                                    <Grid item xs={5} md={4} lg={5}>
                                        <Typography variant="body2">
                                            {t("ADDTEMP31")}<span className={classes.redAsterisk}> *</span>
                                        </Typography>
                                        <CustomInput
                                            id="TemplateLabel23"
                                            value={field.label}
                                            placeholder='Enter Field Name'
                                            handleChange={(value) =>
                                                handleFieldChange(index, field, value)
                                            }
                                            name="label"
                                            size="small"
                                            fullWidth
                                            InputProps={field.id ? { readOnly: true } : {}}
                                            resetError={() => console.log()}
                                        />
                                    </Grid>
                                    <Grid item xs={5} md={4} lg={5}>
                                        <Typography variant="body2">
                                            {t("ADDTEMP31")}<span className={classes.redAsterisk}> *</span>
                                        </Typography>
                                        <CustomInput
                                            id="TemplateLabel24"
                                            value={field.type}
                                            placeholder='Enter Field Name'
                                            handleChange={(value) =>
                                                handleFieldChange(index, field, value)
                                            }
                                            name="type"
                                            size="small"
                                            fullWidth
                                            InputProps={field.id ? { readOnly: true } : {}}
                                            resetError={() => console.log()}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            {newFields.map((field, index) => (
                                <Grid key={index} item container spacing={2}>
                                    <Grid item xs={5.5} md={4.5} lg={5.5}>
                                        <Typography variant="body2">
                                            {t("ADDTEMP31")}<span className={classes.redAsterisk}> *</span>
                                        </Typography>
                                        <CustomInput
                                            id="TemplateLabel25"
                                            value={field.label}
                                            placeholder='Enter Field Name'
                                            handleChange={(value) =>
                                                handleFieldChange(index, field, value)
                                            }
                                            name="label"
                                            size="small"
                                            fullWidth
                                            InputProps={field.id ? { readOnly: true } : {}}
                                            resetError={() => console.log()}
                                        />
                                    </Grid>
                                    <Grid item xs={5.5} md={4.5} lg={5.5}>
                                        <Typography variant="body2">
                                            {t("ADDTEMP32")}<span className={classes.redAsterisk}> *</span>
                                        </Typography>
                                        <Autocomplete
                                            options={types}
                                            size="small"
                                            value={field.type}
                                            onChange={(event, value) => {
                                                handleFieldChange(index, field, {
                                                    target: { name: "type", value },
                                                });
                                            }}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Data Type"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { height: '46px' },
                                                    }}
                                                />
                                            )}
                                            menuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={1} xs={0.5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "35px" }}>
                                        {!field.id ? (
                                            <Tooltip arrow title="Remove Field">
                                                <Plus id="TemplateLabel-button-02"
                                                    width={"40px"}
                                                    onClick={() => removeField(index)}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item container spacing={2}>
                                <Grid item container style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Tooltip arrow title="Add Field">
                                        <AddIcon id="TemplateLabel-button-02"
                                            width={"40px"}
                                            onClick={handleAddField}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button id='uploadTemp-cancleButton' variant="outlined" color="primary" onClick={handleCancel} sx={{ marginRight: 2 }}> {t("TEM14")}  </Button>
                                    <Button id='uploadTemp-submitButton' variant="contained" color="primary" onClick={handleSubmit}>   {t("TEM16")} </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>}
            </RightViewLayout>
        </Grid>
    );
}
