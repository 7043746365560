import { ThemeProvider } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Toolbar,
    Typography,
    createTheme
} from "@mui/material";
import { Carousel } from "@trendyol-js/react-carousel";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ReactComponent as PublicIcon } from "../../assets/images/WWW Icon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/call (1).svg";
import { ReactComponent as MailOutlineIcon } from "../../assets/images/email (4).svg";
import { ReactComponent as LocationOnIcon } from "../../assets/images/location (16).svg";
import { ReactComponent as PremiumIcon } from "../../assets/industries/premiumIcon.svg";

import goverment from "../../assets/industries/Group 10636.svg";
import fb from "../../assets/industries/Union 367.svg";
import retail from "../../assets/industries/Union 368.svg";
import fashion from "../../assets/industries/Union 369.svg";
import beauty from "../../assets/industries/Union 370.svg";
import finance from "../../assets/industries/Union 371.svg";
import automative from "../../assets/industries/Union 373.svg";
import health from "../../assets/industries/Union 374.svg";
import hospitality from "../../assets/industries/Union 376.svg";
import realestate from "../../assets/industries/Union 378.svg";
import telecomunication from "../../assets/industries/Union 379.svg";
import tech from "../../assets/industries/Union 380.svg";

import { makeStyles } from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import { auto } from "@popperjs/core";
import { t } from "i18next";
import Slider from "react-slick";
import img from "../../assets/images/ZKDIGIMAXLOGO-NEW-1.svg";
import footerImg from "../../assets/images/image300.png";
import Search from '../../components/Search';
import { REACT_INDUSTRIES_BEFORE_LOGIN } from "../../constants/ReactEndPoints";
import ForgotPassword from "../../pages/onboard/ForgotPassword";
import Login from "../../pages/onboard/Login";
import SignupUser from "../../pages/onboard/SingupUser";
import { getAdvertApprovedListLanding, getDefaultTag } from "../../services/AdvertService";
import { getBannerList } from "../../services/BannerService";
import Footer from '../../components/layout-components/Footer';

export default function Header() {

    const [loginOpen, setLoginOpen] = useState(false);
    const [registerOpen, setRegisterOpen] = useState(false);
    const [forgotPassOpen, setForgotPassOpen] = useState(false);

    const navigate = useNavigate();
    const handleLoginOpen = () => {
        setOpen(false);
        setLoginOpen(true);
        setRegisterOpen(false); // Close register if open
    };
    const handleRegisterOpen = () => {
        setLoginOpen(false); // Close login  if open
        setRegisterOpen(true);
    };

    const handleLoginClose = () => {
        setLoginOpen(false);
    };
    const handleRegisterClose = () => {
        setRegisterOpen(false);
    };

    const handleCreateAccount = () => {
        setLoginOpen(false);
        setRegisterOpen(true);
    }

    const handleAlreadyAccount = () => {
        setRegisterOpen(false);
        setLoginOpen(true);
        setForgotPassOpen(false);
    }

    const handleForgotPass = () => {
        setForgotPassOpen(true);
        setLoginOpen(false);
    };
    const handleforgotPassClose = () => {
        setLoginOpen(false);
        setForgotPassOpen(false)
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: "#0971f1",
                darker: "#053e85",
            },
            neutral: {
                main: "#F7FBFE",
                contrastText: "black",
            },
        },
    });


    const useStyles = makeStyles((theme) => ({
        customClass: {
            '& .MuiDialog-paper': {
                backgroundColor: 'transparent',
            },
        },
    }));

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        adaptiveHeight: true
    };



    useEffect(() => {
        loadData();
        loadBannerList();
    }, [])

    /*    useEffect(() => {
           loadTemplate()
       }, []) */

    const [image, setImage] = useState("");
    const templatesImages = [image];
    const flattenedArray = templatesImages.flat();
    const country = "";
    const loadData = () => {
        getAdvertApprovedListLanding(country).then(
            (res) => {
                if (res?.data?.code === "ZKCSAE0001") {
                    setImage(res?.data?.data)
                }
            }
        );
    }

    const [bannerList, setBannerList] = useState("");
    const bannerImages = [bannerList];
    const bannerlist = bannerImages.flat();

    const loadBannerList = () => {
        getBannerList().then((res) => {
            console.log("res", (res))
            if (res?.data?.code === "ZKCSAE0001") {
                setBannerList(res?.data?.data)
            }
        }
        );
    }

    const templates = [
        "New Template",
        "Menu Board",
        "Events & Promotions",
        "Recently Viewed",
        "Most Viewed"
    ];

    const categories = [
        { name: t("LAN3"), imageUrl: goverment },
        { name: t("LAN4"), imageUrl: fb },
        { name: t("LAN5"), imageUrl: retail },
        { name: t("LAN6"), imageUrl: fashion },
        { name: t("LAN7"), imageUrl: beauty },
        { name: t("LAN8"), imageUrl: finance },
        { name: t("LAN9"), imageUrl: automative },
        { name: t("LAN10"), imageUrl: health },
        { name: t("LAN11"), imageUrl: hospitality },
        { name: t("LAN12"), imageUrl: realestate },
        { name: t("LAN13"), imageUrl: telecomunication },
        { name: t("LAN14"), imageUrl: tech },
    ];

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [open, setOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");

    const handleOpen = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const getImageUrlCount = (template) => {
        return flattenedArray.filter(
            (item) => item.template === template && (!selectedCategory || item.category === selectedCategory)
        ).length;
    };

    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchIconClick = (categoryName) => {
        console.log("handleSearchIconClick", categoryName)
        setSelectedCategory(categoryName);
    };


    const [selectedCategoryName, setSelectedCategoryName] = useState('Health');

    /* const loadTemplate = () => {
        getTemplatesByCategory(selectedCategoryName).then(
            (res) => {
                if (res?.data?.code === "ZKCSAE0001") {
                    setSelectedCategoryName(res?.data?.data);
                }
            }
        );
    } */

    const gridContainerStyle = {
        display: 'grid',
        backgroundImage: `url(${footerImg})`,
        backgroundSize: 'cover', // Adjusts the image to cover the grid container
        backgroundPosition: 'center', // CFgetTemplatesByCategoryenters the image within the container
        height: '299px',
        width: "769px",
        // border: '2px solid red'
    };

    const handleSearchClick = (categoryName) => {
        navigate(REACT_INDUSTRIES_BEFORE_LOGIN, { state: { categoryName: categoryName } })
        setSelectedCategory(categoryName);
    };

    // useEffect(() => {
    //     loadDefaultTag();
    // }, [])

    // const [defaultTag, setDefaultTag] = useState([])

    // const loadDefaultTag = () => {
    //     getDefaultTag().then((res) => {
    //         alert(JSON.stringify(res?.data?.data))
    //         if (res?.data?.code === "ZKCSAI0001") {
    //             setDefaultTag(res?.data?.data);
    //         }
    //     });
    // }



    const classes = useStyles();

    return (
        <Grid container width="100%" className="gridContainerStyle1" >
            <Grid container width="100%" margin="auto">
                <ThemeProvider theme={theme}>
                    <AppBar
                        position="static"
                        color="default"
                        elevation={0}
                        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor: '#fff' }}
                    >
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <Grid noWrap sx={{ flexGrow: 1 }}>
                                <Link to="/">
                                    <img style={{
                                        marginLeft: '10px',
                                        width: "175px", height: "24px", opacity: "1",
                                        background: "transparent url('../../assets/images/ZKDIGIMAXLOGO-NEW-1.svg') 0% 0% no-repeat padding-box"
                                    }}
                                        src={img} alt="Logo" draggable="false" />
                                </Link>
                            </Grid>
                            <Box item p={1}>
                                <Search
                                    onSearch={(e) => handleLoginOpen(e)}
                                    clearSearch={() => handleLoginOpen()}
                                />
                            </Box>
                            <Button id='landing-login' onClick={handleLoginOpen} variant="outlined" sx={{ my: 1, mx: 1.5, textTransform: 'capitalize' }}>
                                {t("LAN2")}
                            </Button>
                            <Button id='landing-register' onClick={() => handleRegisterOpen()} variant="contained" sx={{ my: 1, mx: 1.5, textTransform: 'capitalize' }}>
                                {t("LAN16")}
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <div style={{ width: "100%", backgroundColor: "#F7FBFE", justifyContent: "center", overflowX: "hidden", overflowY: "hidden" }} margin="auto">
                        <div>
                            <Slider {...settings}>
                                {bannerlist.map((item, index) => (
                                    <div key={index}>
                                        <img
                                            style={{ width: "100%", marginBottom: "5px", borderBottom: "5%" }}
                                            src={item.imageUrl}
                                            draggable="false"
                                            alt={item.imageName} />
                                    </div>))
                                }

                            </Slider>
                        </div>
                        <Grid width="100%" margin="auto">
                            <div style={{
                                textAlign: "center",
                                font: "normal normal medium 60px/60px Roboto",
                                paddingTop: "20px",
                                letterSpacing: "0px",
                                color: "#474B4F",
                                opacity: "1",
                                width: "auto",
                                height: "24px",
                                paddingBottom: "30px"
                            }}>
                                {/* <Typography variant="h6">Industries</Typography> */}
                            </div>
                            <Grid style={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                                {categories.map((item, index) => (
                                    <Grid key={index} onClick={() => handleSearchClick(item.name)}
                                        style={{
                                            height: "110px",
                                            opacity: "1",
                                            cursor: "pointer",
                                            textAlign: "center",
                                            alignContent: "center",
                                            padding: "0px 10px"
                                        }}>
                                        <img src={item.imageUrl} alt={item.name} draggable="false" />
                                        <Typography style={{
                                            textAlign: "center",
                                            fontSize: '10px',
                                            top: "853px",
                                            letterSpacing: "0px",
                                            color: "#474B4F",
                                            opacity: "1",
                                        }}>{item.name}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        {templates.map((template, index) => {
                            const imageUrlCount = getImageUrlCount(template);
                            if (imageUrlCount < 1) {
                                return null; // Hide template name if no imageUrls
                            }
                            if (template === null) {
                                return null;
                            }
                            return (

                                <box key={index} width="95%" margin="auto" style={{
                                    top: "928px",
                                    left: "30px",
                                    height: "100px",
                                    opacity: "1",
                                    marginTop: '10px'
                                }}>
                                    <Grid container display={'flex'} >
                                        <Typography className="tempHeading1" ml={8}>{t(template)}</Typography> &nbsp;
                                        {/* <Typography className="tempHeading2">See All</Typography> */}
                                    </Grid>
                                    <Grid style={{ margin: '0px 28px', top: "982px" }}>
                                        <Carousel show={3.9} swiping={true} leftArrow={true}>
                                            {flattenedArray
                                                .filter((item) => template === "" || template === item.template)
                                                .filter((item) => !selectedCategory || item.category === selectedCategory)
                                                .map((item, index) => (
                                                    <Grid key={index}
                                                        style={{
                                                            cursor: "pointer", textAlign: "center",
                                                            top: "982px",
                                                            width: "348px",
                                                            height: "289px",
                                                            opacity: "1",
                                                        }}
                                                    >
                                                        {item?.advertType === 'premium' ?
                                                            (
                                                                <div class="Imagecontainer">
                                                                    <div class="overlay" >
                                                                        <PremiumIcon
                                                                            width={"30px"}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </div>
                                                                    <img onClick={() => handleOpen(item?.previewImage)}
                                                                        src={item?.imageUrl} alt={item?.name} draggable="false"
                                                                        style={{
                                                                            height: '200px',
                                                                            width: '280px',
                                                                            borderRadius: '20px',
                                                                            padding: "5px",
                                                                            border: '1px solid rgba(51, 125, 200, 1)',
                                                                            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <img onClick={() => handleOpen(item?.previewImage)}
                                                                    src={item?.imageUrl} alt={item?.name} draggable="false"
                                                                    className="ImageTransition"
                                                                />)}
                                                        <div className="titleStyle">
                                                            <Typography fontSize={'14px !important'} >{item?.name}</Typography>
                                                        </div>
                                                    </Grid>
                                                ))}
                                        </Carousel>
                                    </Grid>
                                </box>
                            );
                        })}

                        <Grid style={{ display: "flex", width: '100%' }}>

                            <Grid style={{}}>
                                <Grid className='container' style={gridContainerStyle} >

                                </Grid>
                            </Grid>
                            <Grid direction={"column"} style={{ width: '100%', display: "flex", justifyContent: 'flex-end', alignItems: "flex-end", paddingRight: "40px", paddingBottom: '20px' }}>
                                    <Grid mr={'48px'}><img src={img} alt="Company Logo" draggable="false" style={{ width: 150, height: auto, marginBottom: '10px' }} /></Grid>
                                    <Grid display={'flex'} mb={'10px'} mr={'35px'}>
                                        <PublicIcon />
                                        <Typography variant="body2"><Link style={{ color: '#474B4F', opacity: '0.8', marginLeft: '10px' }} href="www.zkdigimax.com" target="_blank" rel="noopener noreferrer">www.zkdigimax.com</Link></Typography>
                                    </Grid>
                                    <Grid display={'flex'} mb={'10px'} mr={'7px'}>
                                        <MailOutlineIcon sx={{ marginRight: 2 }} />
                                        <Typography variant="body2"><Link style={{ color: '#474B4F', opacity: '0.8', marginLeft: '10px' }} href="mailto:support@zkdigimax.com">support@zkdigimax.com</Link></Typography>
                                    </Grid>

                                    <Grid display={'flex'} mb={'10px'}>
                                        <LocationOnIcon sx={{ marginRight: 2 }} />
                                        <Typography variant="body2" style={{ color: '#474B4F', opacity: '0.8', marginLeft: '10px', }}>Mangkuluhur City,JI. Gatot <br />
                                            <Typography variant="body2" style={{ color: '#474B4F', opacity: '0.8', }}>Subroto No.Kav. 1, 12930,</Typography>
                                            <Typography variant="body2" style={{ color: '#474B4F', opacity: '0.8', }}>DKI Jakarta</Typography>
                                        </Typography>
                                    </Grid>

                            </Grid>


                        </Grid>
                    </div>

                </ThemeProvider >

                <Dialog open={open} onClose={handleClose} maxWidth="md" style={{ backdropFilter: 'blur(5px)' }} className={classes.customClass}>
                    <DialogTitle>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ color: '#fff' }}>{t("TEM12")}</Typography>
                            <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                <EditIcon id='landing-edit' onClick={handleLoginOpen} style={{ cursor: "pointer", marginRight: '15px', color: '#fff' }} />
                                <CloseIcon id='landing-close' onClick={handleClose} style={{ cursor: "pointer", color: '#fff' }} />
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className="container-image-new">
                        <img src={selectedImageUrl} alt="Selected Template" draggable="false" />
                    </DialogContent>
                </Dialog>

                <Dialog open={loginOpen} maxWidth={'md'} style={{ backdropFilter: 'blur(5px)' }}>
                    <Login id='loginIcon' onClose={handleLoginClose} handleCreateAccount={handleCreateAccount} handleForgotPass={handleForgotPass}></Login>
                </Dialog>

                <Dialog open={registerOpen} maxWidth={'md'} style={{ backdropFilter: 'blur(5px)' }}>
                    <SignupUser id='closeIcon' onClose={handleRegisterClose} handleAlreadyAccount={handleAlreadyAccount}></SignupUser>
                </Dialog>

                <Dialog open={forgotPassOpen} maxWidth={'md'} style={{ backdropFilter: 'blur(5px)' }}>
                    <ForgotPassword id='landing-forgotPass' onClose={handleforgotPassClose} handleCreateAccount={handleAlreadyAccount}></ForgotPassword>
                </Dialog>
            </Grid >
            <Footer />
        </Grid>

    );
}
