import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import { getTemplateImages, updateTemplate } from "../../../services/AdvertService";
import { REACT_TEMPALTE_LIST } from "../../../constants/ReactEndPoints";
import { t } from "i18next";
export default function TemplateImages() {

    const [thumbnailImage, setThumbnailImage] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [placeHolderImage, setPlaceHolderImage] = useState("");
    const [id, setId] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getTemplateImages(location.state?.templateId)
            .then((response) => {
                console.log(response);
                setId(location.state?.templateId);
                if (response.data.code === "ZKCSAI0001") {
                    setThumbnailImage(response.data.data.thumbnailImage);
                    setPreviewImage(response.data.data.previewImage);
                    setPlaceHolderImage(response.data.data.placeHolderImage);
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            });
    }, []);

    const handleReject = () => {
        setId(location.state?.templateId);
        updateTemplate(id, "Rejected").then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("APPUP5"), {
                        position: "top-right",
                    });
                    navigate(REACT_TEMPALTE_LIST);
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        })

    };

    const handleSubmit = async (id) => {
        setId(location.state?.templateId);
        updateTemplate(id, "Approved").then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("APPUP6"), {
                        position: "top-right",
                    });
                    navigate(REACT_TEMPALTE_LIST);
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        })
    };

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    return (
        <Grid >
            <RightViewLayout
                title={t("USER0039")}
                navigateBack={'/TemplateAuditList'}
            /* actions={actions} */
            >
                <Grid display={"flex"}>
                    <Grid item xs={4} display="flex" flexDirection="column" backgroundColor="#F7FBFE" marginLeft={'1%'}>
                        <Typography>{t("USER0040")}</Typography>
                        <img src={thumbnailImage} alt="Preview"
                            draggable="false" onDragStart={handleDragStart} />
                    </Grid>
                    <Grid item xs={4} display="flex" flexDirection="column" backgroundColor="#F7FBFE" marginLeft={'1%'}>
                        <Typography>{t("USER0041")}</Typography>
                        <img src={previewImage} alt="Preview"
                            draggable="false" onDragStart={handleDragStart} />
                    </Grid>
                    <Grid item xs={4} display="flex" flexDirection="column" backgroundColor="#F7FBFE" marginLeft={'1%'}>
                        <Typography>{t("USER0042")}</Typography>
                        <img src={placeHolderImage} alt="Preview"
                            draggable="false" onDragStart={handleDragStart} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} paddingTop={"10px"}>
                    <Grid sx={{ display: 'flex', justifyContent: 'right', marginTop: '20%' }}>
                        <Button id="tempImg-cancleButton" variant="outlined" color="primary" size="medium" onClick={() => handleReject(id)} sx={{ marginRight: 2, textTransform: 'capitalize' }}>{t("USER0043")}</Button>
                        <Button id="tempImg-submitButton" variant="contained" color="primary" size="medium" onClick={() => handleSubmit(id)}>{t("CUS8")}</Button>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}
