import React, { useState } from "react";

import useStyles from "./Styles";

import { Grid, OutlinedInput, TextField, Typography } from "@material-ui/core";
import { FormControl, IconButton, InputAdornment } from "@mui/material"; 
import { ReactComponent as VisibilityIcon } from "../../assets/images/ViewIcon1.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/images/ViewIcon2.svg";
import { t } from "i18next";
import DeatilsTextFieldTitle from "../../components/layout-components/CustomInputLabel";


function SetNewPass(props) {
   
  const {handleResetPassword,handleConfirmPassword,error1,error2} = props
  const classes = useStyles();
    const [newPassword, setNewPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
 
    const handleClickShowConfirmPassword = () => {
      setShowPassword((show) => !show);
    };
    const handleClickShowPassword = () => {
      setNewPassword((show) => !show);
    };
  
    const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        style={{ padding: "5%" }}
      >
        <Grid item xs={12} style={{marginBottom:'15px'}}>
        <DeatilsTextFieldTitle titleValue={t("Set New Password")} required />
          <FormControl variant="outlined" fullWidth margin="normal" id="password" sx={{ mt: '0px' }}>
                <OutlinedInput
                  name="password"
                  onChange={(e) => handleResetPassword(e.target.value)}
                  placeholder="Enter New Password"
                  id="outlined-adornment-password"
                  autoComplete="false"
                  type={newPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {newPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
          {error1 && <div className={classes.redColor}>{error1}</div>}
        </Grid>

        <Grid item xs={12}>
        <DeatilsTextFieldTitle titleValue={t("PRO15")} required />

          <FormControl variant="outlined" fullWidth margin="normal" id="password" sx={{ mt: '0px' }}>
                <OutlinedInput
                  name="password"
                  onChange={(e) => handleConfirmPassword(e.target.value)}
                  placeholder="Enter Confirm Password"
                  id="outlined-adornment-password"
                  autoComplete="false"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

          {error2 && <div className={classes.redColor}>{error2}</div>}
        </Grid>
      </Grid>
    </>
  );
}

export default SetNewPass;
