import { Autocomplete, Box, Button, Divider, Grid, InputLabel, TextField, TextareaAutosize, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_DASHBOARD, REACT_HOME_SCREEN } from "../../../constants/ReactEndPoints";
import { getAttributesByTemplateId, getTemplateImages } from "../../../services/AdvertService";
import OrderService, { createOrder } from "../../../services/Orderservice";
import useStyles from '../../onboard/Styles';
import DropzoneComponent from "../../../components/layout-components/DropzoneComponent";
import CustomInput from "../../../components/CustomInput";
import { t } from 'i18next';



export default function OrderTemplate(props) {

    const classes = useStyles();
    const navigate = useNavigate();
    const [title1, setTitle1] = useState("");
    const [bodyContent, setBodyContent] = useState("");
    const [webSite, setWebSite] = useState("");
    const [otherChanges, setOtherChanges] = useState("");
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    const [message7, setMessage7] = useState("");
    const [imageurl, setImageUrl] = useState("");
    const [imageId, setImageId] = useState('');
    const [refrenceImage, setRefrenceImage] = useState(null);
    const [refrenceImageUrl, setRefrenceImageUrl] = useState(null);
    const [logoImg, setLogoImg] = useState(null);
    const [logoImgUrl, setLogoImgUrl] = useState(null);
    const [file1, setFile1] = useState("");
    const [file, setFile] = useState("");
    const location = useLocation();
    const token = localStorage.getItem('USER_TOKEN');
    const userId = localStorage.getItem('USER_NAME');
    const customerName = localStorage.getItem('FIRST_NAME');

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const title1Handler = (event) => {
        const values = event.target.value;
        setTitle1(values);
        if (values.trim() === '') {
            setMessage1(t("ORDTEM1"));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,20}$/.test(values)) {
            setMessage1(t("ORDTEM2"));
        } else {
            setMessage1('');
        }
    };

    const handleBodyContent = (event) => {
        const values = event.target.value;
        setBodyContent(values);
        if (values.trim() === '') {
            setMessage3(t("ORDTEM3"));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,500}$/.test(values)) {
            setMessage3(t("ORDTEM4"));
        } else {
            setMessage3('');
        }
    }

    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,3})(\/\S*)?(?<!\.com\.\4)$/;
    const handlerWebSite = (event) => {
        const values = event.target.value;
        setWebSite(values);
        if (values.trim() === '') {
            setMessage4(t("ORDTEM5"));
        } else if (!urlPattern.test(values)) {
            setMessage4(t("ORDTEM6"));
        } else {
            setMessage4('');
        }
    }

    const handleOtherChanges = (event) => {
        const values = event.target.value;
        setOtherChanges(values);
        // if (values.trim() === '') {
        //     setMessage5('Other Changes are required');
        // }
        if (values.trim() !== '') {
            if (!/^(?! )(?!.*\s{2})[A-Za-z0-9,&\s@#!$%^&*()_+|[\]{};:'"<>,.?/~`=]{1,500}$/.test(values)) {
                setMessage5(t("ORDTEM7"));
            } else {
                setMessage5('');
            }
        }
        // else if(values.trim() === ''){
        //     setMessage5('');
        // }
        else {
            setMessage5('');
        }
    }

    const handleImageUploadRefrence = (excelData) => {
        const file = excelData[0];
        // if (!file) {
        //     setMessage6('Please upload a valid image (JPEG, PNG or JPEG)');
        // } 
        if (file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
                setMessage6('');
                setFile1(file);
                setRefrenceImage(file.name);
                setRefrenceImageUrl(URL.createObjectURL(file));
                props.getExcelFile(file);
            } else {
                setMessage6(t("ORDTEM8"));
            }
        }
        else if (file === undefined) {
            setMessage6(t("ORDTEM8"));
        }
    };

    const handleLogoImageUpload = (excelData) => {
        const file = excelData[0];
        if (file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

            if (allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
                setMessage7('');
                setFile(file);
                setLogoImg(file.name);
                setLogoImgUrl(URL.createObjectURL(file));
                props.getExcelFile(file);
            } else {
                setMessage7(t("ORDTEM8"));
            }
        }
        else if (file === undefined) {
            setMessage7(t("ORDTEM8"));
        }
    };

    const [placeHolderImage, setPlaceHolderImage] = useState("");
    useEffect(() => {
        getTemplateImages(location.state?.imageId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        setImageId(location.state?.imageId);
                        setImageUrl(response.data.data.previewImage);
                        setPlaceHolderImage(response.data.data.placeHolderImage)
                        console.log(imageId + " IMAGE ID");
                        console.log(imageurl);
                    } else {
                        toast.error(t("ORDTEM9"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("ORDTEM9"), {
                        position: "top-right",
                    });
                }
            });
    }, []);

    const [newLabels, setNewLabels] = useState([]);
    const [customePayload, setCustomePayload] = useState({});

    useEffect(() => {
        getAttributesByTemplateId(location.state?.imageId).then((res) => {
          if (res?.data?.data && res?.data?.code === "ZKCSAI0001") {
            setNewLabels(res.data.data);
          }
        });
      }, []);

      const handleCustomChange = (name, value) => {
        setCustomePayload((prevPayload) => ({
          ...prevPayload,
          [name]: value,
        }));
      };


    const handleSubmit = async (event) => {
        const advertId = imageId;
        const country = localStorage.getItem("COUNTRY");
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file1', file1);
        // formData.append("token", localStorage.getItem('USER_TOKEN'));
        const payload = {
            title1,
            bodyContent,
            webSite,
            otherChanges,
            advertId,
            userId,
            customerName,
            country
        }
        formData.append('payload', JSON.stringify(payload));
        formData.append('customPayload', JSON.stringify(customePayload));

        if (title1 === null || bodyContent === null || webSite === null || title1 === '' || bodyContent === '' || webSite === '') {
            if (title1 === '') {
                setMessage1(t("ORDTEM10"));
            }
            if (bodyContent === '') {
                setMessage3(t("ORDTEM10"));
            }
            if (webSite === '') {
                setMessage4(t("ORDTEM10"));
            }
            // if (otherChanges === '') {
            //     setMessage5('Please Fill the given fields');
            // }


        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,20}$/.test(title1)) {
            setMessage1(t("ORDTEM2"));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,500}$/.test(bodyContent)) {
            setMessage3(t("ORDTEM4"));
        } else if (!/^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,3})(\/\S*)?(?<!\.com\.\4)$/.test(webSite)) {
            setMessage4(t("ORDTEM6"));
        } else if (message5 !== '') {
            if (otherChanges !== '') {
                if (!/^(?! )(?!.*\s{2})[A-Za-z0-9,&\s@#!$%^&*()_+|[\]{};:'"<>,.?/~`=]{1,500}$/.test(otherChanges)) {
                    setMessage5(t("ORDTEM7"));
                }
            }
        }
        else if (message6 !== '') {
            setMessage6(t("ORDTEM8"));
        } else if (message7 !== '') {
            setMessage7(t("ORDTEM8"));
        }
        else {

            OrderService.createOrder(formData).then((res) => {
                if (res.data.code === 'ZKCSAI0001') {
                    console.log(res)
                    toast.success(t("ORDTEM12"), {
                        position: "top-right"
                    });
                    navigate(REACT_HOME_SCREEN);
                } else if (res.data.code === 'ZKCSAE0020') {
                    toast.error(t("ORDTEM13"), {
                        position: "top-right"
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "top-right"
                    });
                }
            })
        }
    }

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    return (
        <Grid >
            <RightViewLayout
                title={t("TEMP004")}
                navigateBack={'/HomeScreen'}
            // actions={actions}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" flexDirection="row">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} backgroundColor="#FFF">
                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500 }} >{t("TEMP005")}</Typography>
                        <Box marginTop={'5%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}  >
                            <img display='flex' src={imageurl} alt="Preview"
                                draggable="false"
                                onDragStart={handleDragStart}
                                style={{ height: 'auto', width: '420px' }} />
                        </Box>
                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500 }} >{t("TEMP006")}</Typography>
                        <Box marginTop={'5%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}  >
                            <img display='flex' src={placeHolderImage}
                                draggable="false"
                                onDragStart={handleDragStart}
                                alt="Preview" style={{ height: 'auto', width: '420px' }} />
                        </Box>
                    </Grid  >
                    <Divider orientation="vertical" flexItem />

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} backgroundColor="#FFF">

                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500, }} >{t("TEMP007")}</Typography>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography"> {t("TEMP008")} <span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={title1} name="title" placeholder={t("TEMP014")}  onChange={title1Handler}
                                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message1 && <div className={classes.redColor1}>{message1}</div>}
                        </Grid>
                        {/*  <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography"> Title 2 <span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={title2} name="title2" placeholder='Enter Title2' onChange={title2Handler}
                                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message2 && <div className={classes.redColor1}>{message2}</div>}
                        </Grid> */}
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography"> {t("TEMP009")} <span className={classes.redAsterisk}>  *</span></Typography>
                            <TextareaAutosize id="myInput" value={bodyContent} onChange={handleBodyContent}
                                placeholder={t("TEMP015")} required className="textAreaStyle"
                                minRows={3} />
                            {message3 && <div className={classes.redColor1}>{message3}</div>}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography"> {t("TEMP010")}<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={webSite} name="webSite" placeholder={t("TEMP016")} onChange={handlerWebSite} style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message4 && <div className={classes.redColor1}>{message4}</div>}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography"> {t("TEMP011")}<span className={classes.redAsterisk}>  </span></Typography>
                            <TextareaAutosize id="myInput" value={otherChanges} onChange={handleOtherChanges}
                                placeholder={t("TEMP017")}
                                className="textAreaStyle" minRows={3} />
                            {message5 && <div className={classes.redColor1}>{message5}</div>}
                        </Grid>
                        {newLabels.map((field, index) => (
                  <Grid key={index} item xs={12} sm={9} ml={'25px'} pt={'20px'}>
                    <InputLabel className="viewTypography" >
                      {field.label}
                    </InputLabel>
                     {/* <Typography className="viewTypography"> {field.label}</Typography> */}
                    {field.type === "Enum" ? (
                      <Autocomplete
                        options={field.options}
                        value={customePayload[field.name]}
                        onChange={(event, value) =>
                          handleCustomChange(field.name, value)
                        }
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                        // <TextareaAutosize id="myInput"
                        // value={customePayload[field.name]}
                        // handleChange={(event) =>
                        //   handleCustomChange(field.name, event.target.value)
                        // }
                        // placeholder={`Enter ${field.label}`}
                        // className="textAreaStyle" minRows={3} />

                      <CustomInput 
                        id="AddDynamicTextFiled"
                        size="small"
                        value={customePayload[field.name]}
                        placeholder={`Enter ${field.label}`}
                        handleChange={(event) =>
                          handleCustomChange(field.name, event.target.value)
                        }
                        name={field.name}
                        resetError={() => console.log()}
                        // fullWidth
                        validation={
                          field.type === "Number" ? "numeric" : "alpha-numeric"
                        }
                      />
                    )}
                  </Grid>
                ))}
                        <Grid xs={12} sm={9} style={{ display: 'flex' }}>
                            <Grid >
                                <Typography className="viewTypography"> {t("TEMP012")}<span className={classes.redAsterisk}>  </span></Typography>

                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadRefrence(excelData)}>
                                    {refrenceImageUrl ? (
                                        <img src={refrenceImageUrl} alt="RefrenceImage" style={{ width: '200px', height: '200px', marginLeft: '27px' }} />
                                    ) : (
                                        <Box mt={2} p={5} sx={{
                                            border: '2px dashed #36C96D', borderRadius: '5px',
                                            backgroundColor: "rgba(54, 201, 109,0.1)", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '200px', marginLeft: '30px'
                                        }} >
                                            <Typography sx={{ fontSize: "14px", textAlign: 'center', opacity: '0.8' }}>
                                                {refrenceImage !== null ? (
                                                    refrenceImage
                                                ) : t("BAN02")}
                                            </Typography>

                                        </Box>
                                    )}
                                    {message6 && <div style={{ marginLeft: "29px" }} className={classes.redColor}>{message6}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid >
                                <Typography className="viewTypography"> {t("TEMP013")}<span className={classes.redAsterisk}>  </span></Typography>

                                <DropzoneComponent getExcelFile={(excelData) => handleLogoImageUpload(excelData)}>
                                    {logoImgUrl ? (
                                        <img src={logoImgUrl} alt="Logo" draggable="false" style={{ width: '200px', height: '200px', marginLeft: '27px' }} />
                                    ) : (
                                        <Box mt={2} p={5} sx={{
                                            border: '2px dashed #36C96D', borderRadius: '5px',
                                            backgroundColor: "rgba(54, 201, 109,0.1)", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '200px', marginLeft: '30px'
                                        }} >
                                            <Typography sx={{ fontSize: "14px", textAlign: 'center', opacity: '0.8' }}>
                                                {logoImg !== null ? (
                                                    logoImg
                                                ) : t("BAN02")}
                                            </Typography>

                                        </Box>
                                    )}
                                    {message7 && <div style={{ marginLeft: "29px" }} className={classes.redColor}>{message7}</div>}
                                </DropzoneComponent>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Box display="flex" width={'100%'} justifyContent={"flex-start"} gap={'10px'} ml={'25px'} mt={'25px'}>
                                <Button id="orderTemplate-cancleButton" variant="outlined" color="primary" size="medium" onClick={handleCancel}  >{t("TEM14")}</Button>
                                <Button id="orderTemplate-submitButton" variant="contained" color="primary" size="medium" onClick={handleSubmit}>{t("TEM16")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}