import { Box, Button, Divider, Grid, TextField, TextareaAutosize, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_AUDIT_TEMPLATES, REACT_DASHBOARD, REACT_HOME_SCREEN } from "../../../constants/ReactEndPoints";
import AdvertService, { getTemplateImages, updateTemplateById } from "../../../services/AdvertService";
import OrderService from "../../../services/Orderservice";
import useStyles from '../../onboard/Styles';
import { t } from "i18next";



export default function EditTemplates() {

    const classes = useStyles();
    const navigate = useNavigate();
    const [templateName, setTemplateName] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState("");
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [imageurl, setImageUrl] = useState("");
    const [imageId, setImageId] = useState('');
    const location = useLocation();
    const token = localStorage.getItem('USER_TOKEN');
    const userId = localStorage.getItem('USER_NAME');
    const customerName = localStorage.getItem('FIRST_NAME');

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const templateNameHandler = (event) => {
        const values = event.target.value;
        setTemplateName(values);
        if (values.trim() === '') {
            setMessage1(t("EDITTEM05"));
        }  else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,20}$/.test(values)) {
            setMessage1(t("EDITTEM06"));
        } else {
            setMessage1('');
        }
    };

    const descriptionHandler = (event) => {
      const values = event.target.value;

      setDescription(values);
      if (values.trim() === '') {
          setMessage2(t("ADDTEMP21"));
      } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,500}$/g.test(values)) {
          setMessage2(t("ADDTEMP22"));
      } else {
          setMessage2('');
      }
    };

    const tagsHandler = (event) => {
      const values = event.target.value;
      setTags(values);
      if (values.trim() === '') {
          setMessage3(t("ADDTEMP19"));
      } else if (!/^(?!^\s*$)(?!.*\s{2})(?!.*,,)[A-Za-z,&\s]{1,150}$/.test(values)) {
          setMessage3(t("ADDTEMP22"));
      } else {
          setMessage3('');
      }
  };

    useEffect(() => {
        getTemplateImages(location.state?.templateId)
            .then((response) => {
                if (response !== undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        setImageId(location.state?.id);
                        setImageUrl(response.data.data.previewImage);
                        setTemplateName(response.data.data.templateName)
                        setDescription(response.data.data.description)
                        setTags(response.data.data.tags)
                        console.log(imageId + " IMAGE ID");
                        console.log(imageurl);
                    } else {
                        toast.error(t("TEM11"), {
                            position: "top-right",
                        });
                    }
                } 
            });
    }, []);

    const handleSubmit = async (event) => {
        const advertId = location.state?.templateId;
        const payload = ({
          templateName,
          description,
            tags,
            advertId,
            userId,
            customerName
        })

        if (templateName === null || description === null  || tags === null  || templateName === '' || description === ''  || tags === '' ) {
            if (templateName === '') {
                setMessage1(t("ERR001"));
            }
            if (description === '') {
                setMessage2(t("ERR001"));
            }
            if (tags === '') {
                setMessage3(t("ERR001"));
            }
            

        }  else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,20}$/.test(templateName)) {
            setMessage1(t("ADDTEMP18"));
        } else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z0-9,&\s!@#$%^&*()\-+_=;:'",.<>?/\\[\]{}|`~]{1,500}$/.test(description)) {
            setMessage2(t("ADDTEMP22"));
        } else if (!/^(?!^\s*$)(?!.*\s{2})(?!.*,,)[A-Za-z,&\s]{1,150}$/.test(tags)) {
            setMessage3(t("ADDTEMP20"));
        } else {
          updateTemplateById(payload).then((res) => {
                if (res.data.code === 'ZKCSAI0001') {
                    console.log(res)
                    toast.success(t("EDITTEM08"), {
                        position: "top-right"
                    });
                    navigate(REACT_AUDIT_TEMPLATES);
                } else if (res.data.code === 'ZKCSAE0078') {
                    toast.error(t("EDITTEM07"), {
                        position: "top-right"
                    });
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right"
                    });
                }
            })
        }
    }

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    return (
        <Grid >
            <RightViewLayout
                title={t("EDITTEM01")}
                navigateBack={'/HomeScreen'}
            // actions={actions}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" flexDirection="row" className="OrderStyle">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500 }} >{t("USER0041")}</Typography>
                        <Box marginTop={'5%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}  >
                            <img display='flex' src={imageurl} alt="Preview" 
                            draggable="false"
                            onDragStart={handleDragStart} 
                            style={{ height: 'auto', width: '420px' }} />
                        </Box>
                    </Grid  >
                    <Divider orientation="vertical" flexItem />

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >

                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500, }} >Details to Edit</Typography>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography">{t("TEM3")}<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={templateName} name="templateNames" placeholder={t("ADDTEMP5")} onChange={templateNameHandler}
                                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message1 && <div className={classes.redColor1}>{message1}</div>}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography">{t("EDITTEM02")}<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={description} name="description" placeholder={t("EDITTEM04")} onChange={descriptionHandler}
                                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message2 && <div className={classes.redColor1}>{message2}</div>}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography className="viewTypography">{t("TEM6")}<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={tags} name="tags" placeholder={t("EDITTEM03")} onChange={tagsHandler}
                                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                            {message3 && <div className={classes.redColor1}>{message3}</div>}
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'}  mt={'25px'} marginBottom={'20px'} >
                                <Button id="edit-templateCancle" paddingLeft={'27px'} variant="outlined" color="primary" size="medium" onClick={handleCancel}  >{t("TEM14")}</Button>
                                <Button id="edit-templateSubmit" paddingLeft={'27px'} variant="contained" color="primary" size="medium" onClick={handleSubmit}>{t("TEM16")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}