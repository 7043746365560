import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import { Masonry } from "@mui/lab";
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Toolbar,
    Typography,
    createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img from "../../assets/images/ZKDIGIMAXLOGO-NEW-1.svg";
import footerImg from "../../assets/images/image300.png";
import goverment from "../../assets/industries/Group 10636.svg";
import fb from "../../assets/industries/Union 367.svg";
import retail from "../../assets/industries/Union 368.svg";
import fashion from "../../assets/industries/Union 369.svg";
import beauty from "../../assets/industries/Union 370.svg";
import finance from "../../assets/industries/Union 371.svg";
import automative from "../../assets/industries/Union 373.svg";
import health from "../../assets/industries/Union 374.svg";
import hospitality from "../../assets/industries/Union 376.svg";
import realestate from "../../assets/industries/Union 378.svg";
import telecomunication from "../../assets/industries/Union 379.svg";
import tech from "../../assets/industries/Union 380.svg";
import { ReactComponent as PremiumIcon } from "../../assets/industries/premiumIcon.svg";
import Login from "../../pages/onboard/Login";
import { getTemplatesByCategory } from "../../services/AdvertService";
import Loader from "../Loader";
import { makeStyles } from "@material-ui/core";
import SignupUser from "../../pages/onboard/SingupUser";
import { t } from "i18next";
export default function IndustriesLayout() {

    const [loading, setLoading] = useState(false);
    const theme = createTheme({
        palette: {
            primary: {
                main: "#0971f1",
                darker: "#053e85",
            },
            neutral: {
                main: "#F7FBFE",
                contrastText: "black",
            },
        },
    });

    const gridContainerStyle = {
        display: 'grid',
        backgroundImage: `url(${footerImg})`,
        backgroundSize: 'cover', // Adjusts the image to cover the grid container
        backgroundPosition: 'center', // CFgetTemplatesByCategoryenters the image within the container
        height: '300px'
    };
    useEffect(() => {
        loadData()
    }, [])
    const location = useLocation();
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const templatesImages = [image];

    const flattenedArray = templatesImages.flat();
    const useStyles = makeStyles((theme) => ({
        customClass: {
            '& .MuiDialog-paper': {
                backgroundColor: 'transparent',
            },
        },
    }));
    const classes = useStyles();


    const categories = [
        { name: "Government", imageUrl: goverment },
        { name: "F&B", imageUrl: fb },
        { name: "Retail", imageUrl: retail },
        { name: "Fashion", imageUrl: fashion },
        { name: "Beauty", imageUrl: beauty },
        { name: "Finance", imageUrl: finance },
        { name: "Automotive", imageUrl: automative },
        { name: "Health", imageUrl: health },
        { name: "Hospitality", imageUrl: hospitality },
        { name: "Real Estate", imageUrl: realestate },
        { name: "Telecommunication", imageUrl: telecomunication },
        { name: "Tech", imageUrl: tech },
    ];
    var category = location.state?.categoryName;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const loadData = () => {
        setLoading(true);
        const country = localStorage.getItem("COUNTRY");
        getTemplatesByCategory(category, country).then(
            (res) => {
                if (res.data.code === "ZKCSAE0001") {
                    setSelectedCategory(category);
                    setImage(res.data.data);
                    setLoading(false);
                    if (res.data.code === "ZKCSAE0001") {
                    } else {
                    }
                }
            }
        );
    }
    const [open, setOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const handleOpen = (state, imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEdit = () => {
        alert("hello")
    };

    const handleSearchClick = (categoryName) => {
        category = categoryName;
        setSelectedCategory(categoryName);
        loadData();
    };

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    const handleCreateAccount = () => {
        setLoginOpen(false);
        setRegisterOpen(true);
    }

    const handleForgotPass = () => {
        setForgotPassOpen(true);
        setLoginOpen(false);
    };


    const [loginOpen, setLoginOpen] = useState(false);
    const [registerOpen, setRegisterOpen] = useState(false);
    const [forgotPassOpen, setForgotPassOpen] = useState(false);

    const handleLoginOpen = () => {
        setOpen(false);
        setLoginOpen(true);
        setRegisterOpen(false); // Close register if open
    };
    const handleRegisterOpen = () => {
        setLoginOpen(false); // Close login  if open
        setRegisterOpen(true);
    };

    const handleLoginClose = () => {
        setLoginOpen(false);
    };

    const handleRegisterClose = () => {
        setRegisterOpen(false);
    };

    const handleAlreadyAccount = () => {
        setRegisterOpen(false);
        setLoginOpen(true);
        setForgotPassOpen(false);
    }



    return (
        <Grid container width="100%" margin={"auto"} overflow={"hidden"} >
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor: '#fff' }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Grid noWrap sx={{ flexGrow: 1 }}>
                        <Link to="/">
                            <img draggable="false" style={{
                                marginLeft: '10px',
                                width: "175px", height: "24px", opacity: "1",
                                background: "transparent url('../../assets/images/ZKDIGIMAXLOGO-NEW-1.svg') 0% 0% no-repeat padding-box"
                            }}
                                src={img} alt="Logo" />
                        </Link>
                    </Grid>
                    {/* <Button onClick={handleLoginOpen} variant="outlined" sx={{ my: 1, mx: 1.5, textTransform: 'capitalize' }}>
                                {t("LAN2")}
                            </Button>
                            <Button onClick={() => handleRegisterOpen()} variant="contained" sx={{ my: 1, mx: 1.5, textTransform: 'capitalize' }}>
                                {t("LAN16")}
                            </Button> */}
                </Toolbar>
            </AppBar>
            <Grid width="100%" margin="auto" paddingTop={'50px'}>
                <Grid style={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                    {categories.map((item, index) => (
                        <Grid key={index} onClick={() => handleSearchClick(item.name)} style={{
                            top: "768p", height: "110px",
                            opacity: "1", cursor: "pointer", textAlign: "center", alignContent: "center", padding: "0px 7px",
                        }}>
                            <img src={item.imageUrl} alt={item.name} />
                            <Typography variant="body2" style={{
                                textAlign: "center",
                                fontSize: '10px',
                                top: "853px",
                                letterSpacing: "0px",
                                color: "#474B4F", opacity: "1",

                            }}>{t(item.name)}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Typography sx={{ color: '#474B4F', opacity: '1', font: 'normal normal 600 18px/29px Roboto', mb: '10px', ml: '30px' }}>{t(selectedCategory)} </Typography>
            {loading ? <Loader /> :
                <Masonry columns={4} spacing={2.5} style={{ marginTop: '10px', marginLeft: '20px' }}>
                    {flattenedArray.map((item, index) => (
                        <Grid onClick={() => handleOpen(item, item?.previewImage)}
                         style={{
                            cursor: "pointer", textAlign: "center",
                            width: "auto", height: 'auto',
                            opacity: "1", paddingBottom: '10px',
                        }}>
                            {item?.advertType === 'premium' ?
                                (
                                    <div class="Imagecontainer">
                                        <div class="overlay1" >
                                            <PremiumIcon
                                                width={"30px"}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                        <img draggable="false" src={item?.previewImage} alt={item?.name} loading="lazy"
                                            style={{
                                                display: 'block',
                                                width: '280px',
                                                height: 'auto',
                                                borderRadius: '5px',
                                                transition: 'all 1s',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img src={item?.previewImage} alt={item?.name} loading="lazy"
                                        draggable="false"
                                       
                                        style={{
                                            display: 'block',
                                            width: '280px',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            transition: 'all 1s',
                                        }}
                                        className="IndustriesImage"
                                    />)}
                        </Grid>
                    ))}
                </Masonry>
            }

            <Dialog open={open} onClose={handleClose} maxWidth="md" style={{ backdropFilter: 'blur(5px)' }} className={classes.customClass}>
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: '#fff' }}>{t("TEM12")}</Typography>
                        <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <EditIcon onClick={handleLoginOpen} style={{ cursor: "pointer", marginRight: '15px', color: '#fff' }} />
                            <CloseIcon onClick={handleClose} style={{ cursor: "pointer", color: '#fff' }} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="container-image-new">
                    <img src={selectedImageUrl} alt="Selected Template" draggable="false" />
                </DialogContent>
            </Dialog>

            <Dialog open={loginOpen} maxWidth={'md'} style={{ backdropFilter: 'blur(5px)' }}>
                <Login onClose={handleLoginClose} handleCreateAccount={handleCreateAccount} handleForgotPass={handleForgotPass}></Login>
            </Dialog>

            <Dialog open={registerOpen} maxWidth={'md'} style={{ backdropFilter: 'blur(5px)' }}>
                <SignupUser onClose={handleRegisterClose} handleAlreadyAccount={handleAlreadyAccount}></SignupUser>
            </Dialog>
        </Grid>


    );
}
