import React, { useEffect, useState } from "react";

import useStyles from "./Styles";

import { Grid, Typography, TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import CommonUtil from "../../util/CommonUtils";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { t } from "i18next";
import DeatilsTextFieldTitle from "../../components/layout-components/CustomInputLabel";

function Authentication(props) {
  const { getValuePassCode, handleEmail } = props;
  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [timer, setTimer] = useState(300);
  const [timerState, setTimerState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timerState) {
      const interval = setInterval(() => {
        console.log("time going to reduced");
        setTimer(timer - 1);
      }, 1000);

      if (timer === 0) {
        setTimerState(false);
        setTimer(300);
        console.log("time= 0");
      }
      return () => clearInterval(interval);
    }
  }, [timer, timerState]);

  const handldeButton = (e) => {
    if (!CommonUtil.isValidEmailLogin(userName)) {
      toast.error(t("LVL0003"), {
        position: "top-right",
      });
    } else {
      UserService.forgotPassword(userName).then((res) => {
        if (res.data.code === "UASE0003") {
          toast.error(t("USER0031"), {
            position: "top-right",
          });
        } else if (res.data.code === "ZKCSAE0000") {
          toast.error(t("USER0032"), {
            position: "top-right",
          });
          navigate("/")
        }else if (res.status === 200) {
          toast.success(t("USER0033"), {
            position: "top-right",
          });
          // console.log(res);
          const emailcode=res.data.data
          // const verificationDecoded = atob(emailcode);
          // console.log("Decoded String:", emailcode);
          const dataToStore = {
            value: emailcode,
            expiration: new Date().getTime() + 5 * 60 * 1000, // Current time + 5 minutes
          };
          localStorage.setItem("emailCode",JSON.stringify(dataToStore))
          // console.log(emailcode);
          // alert(emailcode)
          setTimerState(true);
          console.log("time started ");
        }
      });
    }
  };

  const handleUsername = (e) => {
    setUserName(e.target.value);
    handleEmail(e.target.value);
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        style={{ padding: "3%" }}
      >
        <Grid item xs={12}>
          <DeatilsTextFieldTitle titleValue={t("REG11")} required />
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              name="userName"
              variant="outlined"
              placeholder={t("REG12")}
              onChange={handleUsername}
              style={{ marginRight: "10px" }}
            />

            <TimelapseIcon />
            {timer}
          </Grid>
          <Button id="verifucationCode-button"
            style={{
              display: "flex",
              height: "53px",
              marginBottom: "8%",
              marginTop: "8%",
              backgroundColor: "#1487CA",
            }}
            variant="contained"
            onClick={handldeButton}
            fullWidth
            disabled={timerState}
          >
            {t("USER0027")}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DeatilsTextFieldTitle titleValue={t("USER0029")} required />
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t("USER0028")}
            onChange={(e) => getValuePassCode(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Authentication;
